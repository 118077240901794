






















































import {
  Component, Vue, Watch, Inject,
} from 'vue-property-decorator';
import debounce from 'lodash.debounce';

import RuleTable from '@/components/library-maintenance/rule-table.vue';
import Card from '@/components/material/Card.vue';
import { TableOptions, VDataTableOptions, SnackbarOptions } from '@/models/form';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import LocalState from '@/store/modules/local-state-module';
import { Library, Rule } from '@/models/library-maintenance.d';
import RuleService from '@/services/rule-service';
import LibraryService from '@/services/library-service';
import { backOr } from '@/router';
import axios, { CancelTokenSource } from 'axios';

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    RuleTable,
    EcSnackBar,
  },
})
export default class RulesListView extends Vue {
  @Inject() RuleService!: RuleService;

  @Inject() LibraryService!: LibraryService;

  options: TableOptions = {
    link: true,
  }

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  libraryId = '';

  library: Library = {} as Library;

  libraryTitle = '';

  snackbar = {
    value: false,
    message: '',
    color: '',
  };

  items: Rule[] = [];

  totalItems = 0;

  loading = false;

  page = 1;

  size = localState.defaultPageSize;

  sort: string[] = ['name,asc'];

  filter: string | null = null;

  cancellationToken!: CancelTokenSource;

  get apiFault() { return appState.apiFault; }

  get sortBy() { return this.sort.map((x) => x.split(',')[0]); }

  get sortDesc() { return this.sort.map((x) => x.split(',')[1] === 'desc'); }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    const {
      page = 1,
      size = localState.defaultPageSize,
      sort = ['name,asc'],
      filter,
    } = this.$route.query || {};

    this.libraryId = this.$route.params.libraryId;

    this.LibraryService
      .readSingle(this.libraryId)
      .then((library) => {
        this.library = library;
        this.libraryTitle = `Rules of ${this.library.name}`;
      });

    this.page = +page;
    this.size = +size;
    this.sort = [sort].flat();
    this.filter = filter ? `${filter}` : null;

    this.loadPage();
  }

  private debounceFilter?: (arg: string) => void;

  onFilterChange(newFilter: string) {
    if (!this.debounceFilter) {
      this.debounceFilter = debounce((filter: string | null) => {
        const currentQuery = this.$route.query;
        const { filter: currentFilter } = currentQuery;

        if ((!currentFilter && !filter) || currentFilter === filter) return;

        this.$router.replace({
          query: {
            ...currentQuery,
            page: '1',
            filter: filter || undefined,
          },
        });
      }, 500);
    }

    if (this.debounceFilter) {
      this.debounceFilter(newFilter);
    }
  }

  optionsUpdated(options: VDataTableOptions) {
    const {
      sortBy, sortDesc, page, itemsPerPage,
    } = options;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? 'desc' : 'asc'}`);

    const currentQuery = this.$route.query;
    currentQuery.sort = [currentQuery.sort].flat();

    const newQuery = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
      filter: this.filter,
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }
    if (this.filter?.length === 0) {
      delete newQuery.filter;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.replace({
      query: newQuery,
    });
  }

  back() {
    backOr({name: 'library', params: {id: this.libraryId}});
  }

  pageUpdated(page: number) {
    const currentQuery = this.$route.query;
    currentQuery.sort = [currentQuery.sort].flat();

    if (currentQuery.page === `${page}`) {
      return;
    }

    this.$router.replace({
      query: {
        ...currentQuery,
        page: `${page}`,
      },
    });
  }

  async loadPage() {
    if (!this.libraryId) return;

    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      if (this.cancellationToken)
         this.cancellationToken.cancel('Reloaded list');
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.RuleService.listRules(
        this.libraryId,
        pagination,
        this.filter || undefined,
        this.cancellationToken.token
      );

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.rules;

      localState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbar.message = error;
        this.snackbar.color = 'error';
        this.snackbar.value = true;
      }
    } finally {
      this.loading = false;
    }
  }

  async enableItem(item: Rule, enable: boolean) {
    try {
      const text = enable
      ? 'If you enable this rule, it is likely that it will start to be processed and start generating hits.'
      : 'If you disable this rule, it will no longer be processed and will not generate hits.';

      appState.openDialog({
        title: enable ? 'Enable Rule?' : 'Disable Rule?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: enable ? 'Enable' : 'Disable',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Changing rule ...');
            this.RuleService.setEnabledState(this.libraryId, item.id, enable)
              .then(() => {
                const local = item;
                local.enabled = enable;

                this.snackbarOptions.value = false;
                const message = `Successfully ${enable ? 'enabled' : 'disabled'} rule`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions(message); });
              })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                const message = `Failed to ${enable ? 'enable' : 'disable'} rule: ${error.message}`;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(message); });
              })
            },
          }, {
            name: 'Cancel',
            color: 'primary',
            handler: () => Promise.resolve(false),
          }],
    });
    } catch (error) {
      this.snackbarOptions.value = false;
      const message = `Failed to ${enable ? 'enable' : 'disable'} rule: ${error}`;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(message); });
    }
  }

  async deleteItem(item: Rule) {
    try {
      appState.openDialog({
        title: 'Delete Rule?',
        text: `<p>Are you sure?</p><p>If you delete this rule, it will no longer be processed and will not generate hits.</p>`,
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting rule ...');
            this.RuleService.deleteRule(this.libraryId, item.id)
              .then(() => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully deleted rule'); });
                  this.loadPage();
                })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete rule: ${error.message}`); });
              }
            )
          },
        },
        {
          name: 'Cancel',
          color: 'primary',
          handler: () => Promise.resolve(false),
        }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete rule: ${error}`); });
    }
  }
}
